<template>
	<footer class="footer-distributed">
		<div class="footer-left">

			<img class="logo" src="../assets/img/logo-nombre-white.svg" alt="" srcset="">
			<div class="footer-icons">
				<a href="https://www.instagram.com/tranzfer.me/" target="_blank"><i class="fab fa-instagram"></i></a>
				<a href="https://www.facebook.com/tranzfer.me/" target="_blank"><i class="fab fa-facebook-square"></i></a>
				<a href="https://www.linkedin.com/company/tranzferme/" target="_blank"><i class="fab fa-linkedin"></i></a>
				<a href="https://www.youtube.com/watch?v=onI65RICiSU&feature=youtu.be" target="_blank"><i
						class="fab fa-youtube"></i></a>

			</div>
			<div class="info-footer">
				<p><i class="fab fasfooter fa-whatsapp"></i>{{ $t("espanol") }} (+51) 981 194 689</p>
				<p><i class="fab fasfooter fa-whatsapp"></i>{{ $t("ingles") }} (+1) 813 808 1794 </p>
				<p><i class="far fasfooter fa-envelope"></i> tranzfers@tranzfer.me</p>
			</div>

			



		</div>
		<div class="footer-right">



			<a href="https://reclamovirtual.pe/reclamar/tranzfer-me/lima" target="_blank" class="libro-reclamaciones">
            <img src="../assets/img/libro-reclamaciones.png" alt="Libro de Reclamaciones" />
            <span>Libro de Reclamaciones</span>
        </a>





			<!--  <form action="#" method="post">
        <input type="text" name="email" placeholder="Email">
        <button>Suscribirse</button>
      </form>
	-->
		</div>

	




		<p class="footer-company-name">Copyright © {{ new Date().getFullYear() }} Tranzfer.me</p>

		<div id="whatsapp">
			<a href="https://api.whatsapp.com/send?phone=51981194689" target="_blank">
				<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"
					style="fill:#40C057;">
					<g class="layer">
						<title>Conversar con Tranzfer.me</title>
						<circle cx="40" cy="40" fill="#fff" id="svg_1" r="38" stroke="#000000" stroke-width="0" />
						<path
							d="m57.81072,21.975c-4.48928,-4.5 -10.46786,-6.975 -16.82142,-6.975c-13.11429,0 -23.78571,10.67143 -23.78571,23.78571c0,4.18928 1.09286,8.28215 3.17143,11.89286l-3.375,12.32142l12.61072,-3.31072c3.47143,1.89642 7.38215,2.89286 11.36786,2.89286l0.01072,0c13.10358,0 24.01072,-10.67143 24.01072,-23.78571c0,-6.35357 -2.7,-12.32142 -7.18928,-16.82142l-0.00001,-0.00001l-0.00001,0l-0.00002,0.00001zm-16.82142,36.6c-3.55714,0 -7.03928,-0.95357 -10.07143,-2.75357l-0.71785,-0.42857l-7.47858,1.96072l1.99286,-7.29642l-0.47143,-0.75c-1.98215,-3.15 -3.02142,-6.78215 -3.02142,-10.52142c0,-10.89642 8.87143,-19.76786 19.77858,-19.76786c5.28215,0 10.24286,2.05714 13.97143,5.79642c3.72857,3.73928 6.02142,8.7 6.01072,13.98215c0,10.90714 -9.09642,19.77858 -19.99286,19.77858l0,-0.00002l-0.00001,0l-0.00001,-0.00001zm10.84286,-14.80714c-0.58928,-0.3 -3.51429,-1.73572 -4.06072,-1.92857c-0.54643,-0.20358 -0.94286,-0.3 -1.33928,0.3c-0.39642,0.6 -1.53214,1.92857 -1.88571,2.33572c-0.34286,0.39642 -0.69642,0.45 -1.28571,0.15c-3.49286,-1.74643 -5.78571,-3.11785 -8.08928,-7.07143c-0.61072,-1.05 0.61072,-0.975 1.74643,-3.24643c0.19286,-0.39642 0.09642,-0.73928 -0.05357,-1.03928c-0.15,-0.3 -1.33928,-3.225 -1.83214,-4.41429c-0.48215,-1.15714 -0.975,-0.99642 -1.33928,-1.01785c-0.34286,-0.02142 -0.73928,-0.02142 -1.13572,-0.02142c-0.39642,0 -1.03928,0.15 -1.58571,0.73928c-0.54643,0.6 -2.07858,2.03572 -2.07858,4.96072c0,2.925 2.13214,5.75357 2.42142,6.15c0.3,0.39642 4.18928,6.39642 10.15714,8.97858c3.77143,1.62857 5.25,1.76786 7.13572,1.48928c1.14643,-0.17143 3.51429,-1.43572 4.00714,-2.82857c0.49286,-1.39286 0.49286,-2.58215 0.34286,-2.82857c-0.13928,-0.26786 -0.53572,-0.41785 -1.125,-0.70714l-0.00001,-0.00001l0.00002,-0.00001l-0.00002,-0.00001z"
							fill="currentColor" id="svg_2" />
					</g>
				</svg>
			</a>
		</div>

	


	</footer>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>
.logo {
	height: 30px;
}

.footer-distributed {
	background: #444;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	font: bold 16px sans-serif;
	text-align: left;
	padding: 50px 60px 40px;
	overflow: hidden;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-items: center;
}

.footer-company-name {
	grid-column-start: 1;
	grid-column-end: 3;
}


/* Footer left */

.fasfooter {
	width: 25px;
	text-align: center;
	color: white;
}

.info-footer {
	color: #C3C3C3;
	padding: 10px 0px 20px 0px;
}

.info-footer p {
	padding: 10px 0px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

/* The company logo */

.footer-distributed h3 {
	color: #ffffff;
	font: normal 36px 'Roboto', cursive;
	margin: 0 0 10px;
}

.footer-distributed h3 span {
	color: #5383d3;
}

/* Footer links */

.footer-distributed .footer-links {
	color: #ffffff;
	margin: 10px 0px 10px 0px;
	padding: 0;
}

.footer-distributed .footer-links a {
	display: inline-block;
	line-height: 1.8;
	text-decoration: none;
	color: inherit;
}

.footer-distributed .footer-company-name {
	color: #8f9296;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer social icons */

.footer-distributed .footer-icons {
	margin-top: 25px;
}

.footer-distributed .footer-icons a {
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color: #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

/* Footer Right */

.footer-distributed .footer-right p {
	display: inline-block;
	vertical-align: top;
	margin: 15px 42px 0 0;
	color: #ffffff;
}

/* The contact form */

.footer-distributed form {
	display: inline-block;
}

.footer-distributed form input,
.footer-distributed form textarea {
	display: block;
	border-radius: 3px;
	box-sizing: border-box;
	background-color: #1f2022;
	box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.1);
	border: none;
	resize: none;

	font: inherit;
	font-size: 14px;
	font-weight: normal;
	color: #d1d2d2;

	width: 300px;
	padding: 18px;
}

.footer-distributed ::-webkit-input-placeholder {
	color: #5c666b;
}

.footer-distributed ::-moz-placeholder {
	color: #5c666b;
	opacity: 1;
}

.footer-distributed :-ms-input-placeholder {
	color: #5c666b;
}


.footer-distributed form input {
	height: 50px;
	margin-bottom: 15px;
	outline: none;
}

.footer-distributed form textarea {
	height: 100px;
	margin-bottom: 20px;
}

.footer-distributed form button {
	border-radius: 3px;
	background-color: #33383b;
	color: #ffffff;
	border: 0;
	padding: 15px 50px;
	font-weight: bold;
	float: right;
	font-size: 14px;
}

@media (max-width: 1000px) {

	.footer-distributed {
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-company-name {
		font-size: 12px;
	}

	.footer-distributed form input,
	.footer-distributed form textarea {
		width: 250px;
	}

	.footer-distributed form button {
		padding: 10px 35px;
	}

}

@media (max-width: 900px) {
	.info-section {
		grid-template-columns: 1fr;
	}
}

@media (max-width: 800px) {

	.footer-distributed {
		padding: 30px 20px;
		grid-template-columns: 1fr;
		align-items: center;
		justify-items: center;
	}

	.footer-company-name {
		grid-column-start: 1;
		grid-column-end: 1;
	}

	.footer-distributed .footer-left {
		margin-bottom: 0px;
	}

	.footer-distributed .footer-right {
		margin-bottom: 20px;
	}

	.footer-distributed form {
		margin-top: 0px;
	}

	.footer-distributed form {
		display: block;
	}

	.footer-distributed form button {
		float: none;
	}
}

#whatsapp {
	position: fixed;
	bottom: 20px;
	right: 20px;
}

svg {
	width: 80px;
	filter: drop-shadow(0 1px 4px rgba(0, 0, 0, .4));
}

circle {
	fill: #25d366;
}

path {
	fill: #fff;
}




.footer-right {
    display: flex;
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    height: 100%; /* Asegura que tome toda la altura disponible */
}

.libro-reclamaciones {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
}

.libro-reclamaciones img {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

/* Para asegurar que el contenedor derecho tenga la altura correcta */
.footer-distributed {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch; /* Esto hace que ambas columnas tengan la misma altura */
}

/* Mantener la responsividad */
@media (max-width: 800px) {
    .footer-distributed {
        grid-template-columns: 1fr;
    }
    
    .footer-right {
        margin: 20px 0;
    }
}


.libro-reclamaciones span {
    color: #C3C3C3;     
    font-size: 16px;    
    text-align: center;
}

.libro-reclamaciones img {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}
</style>